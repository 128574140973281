<template>
  <v-container v-if="isLoading" class="d-flex justify-center align-center">
    <v-progress-circular indeterminate color="primary" />
  </v-container>
  <v-container
    class="fill-height"
    v-else-if="promotion && promotion.id && promotionDeadlines && !isAfterAllSubmissionDeadlines"
  >
    <v-row justify="center">
      <v-col lg="8" md="10" sm="10" xs="12">
        <v-form @submit.stop.prevent="onSubmit" auto-complete="no">
          <v-card class="elevation-12">
            <v-toolbar color="info" dark flat>
              <v-toolbar-title>
                <span>{{ finalTitle }}</span>
              </v-toolbar-title>
              <v-spacer />
              <v-tooltip bottom>
                <template v-slot:activator="{ on: on }">
                  <v-btn icon v-on="on" :to="{ name: 'ceurLanding' }">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.translate("Cancel") }}</span>
              </v-tooltip>
            </v-toolbar>

            <v-card-text>
              <ApiError :errors="errors" />
              <v-stepper v-model="e1">
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-form @submit.prevent="onSubmit" v-model="isValid">
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              name="firstName"
                              :label="this.$i18n.translate('First Name')"
                              id="firstName"
                              v-model="form.user.firstName"
                              :rules="rules.firstName"
                              :error-messages="$error.getValidationError(errors, 'firstName')"
                              @input="$error.clearValidationError(errors, 'firstName')"
                              autocomplete="no"
                              class="required"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              name="lastName"
                              :label="this.$i18n.translate('Last Name')"
                              id="lastName"
                              v-model="form.user.lastName"
                              :rules="rules.lastName"
                              :error-messages="$error.getValidationError(errors, 'lastName')"
                              @input="$error.clearValidationError(errors, 'lastName')"
                              autocomplete="no"
                              class="required"
                            />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12">
                            <EmailField
                              name="email"
                              :label="this.$i18n.translate('Email')"
                              id="email"
                              v-model="form.user.email"
                              :rules="rules.email"
                              :error-messages="$error.getValidationError(errors, 'email')"
                              @input="$error.clearValidationError(errors, 'email')"
                              autocomplete="no"
                              class="required"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <TermsAndConditionsView
                              :value="termsAndConditions"
                              @update:valid="isValidTermsAndConditions = $event"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <p>
                              By clicking 'Register' below, I agree to the Sony Electronics Inc.
                              <a href="https://electronics.sony.com/privacy-policy" target="_blank">Privacy Policy </a>
                              and certify that I am a U.S. resident.
                              <a href="https://electronics.sony.com/privacy-policy#DataPractices" target="_blank"
                                >CA Privacy Notice</a
                              >
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-btn color="secondary" @click="backToLanding()">
                              {{ $i18n.translate("Cancel") }}
                            </v-btn>
                          </v-col>
                          <v-col class="text-right">
                            <v-btn
                              color="primary"
                              @click="onSubmit()"
                              :loading="isLoading"
                              :disabled="!isValid || !isValidTermsAndConditions"
                            >
                              {{ $i18n.translate("Register") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <p>*Email will be sent from ConsumerRebatesHq@rebatesupport.com</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <p v-html="finalDescription" />
                    <v-row v-if="!isSuccess">
                      <v-col>
                        <v-btn color="primary" @click="onBack()">
                          {{ $i18n.translate("Back") }}
                        </v-btn>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn color="secondary" @click="backToLanding()">
                          {{ $i18n.translate("Cancel") }}
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row v-else>
                      <v-col class="text-right">
                        <v-btn color="primary" @click="backToLanding()">
                          {{ $i18n.translate("Finish") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <div class="white--text text-center">
      <p class="mb-5" />
      <h2>{{ noPromotionsMessage }}</h2>
      <GoogleReCaptcha :showLegend="false" />
      <p class="mb-5" />
    </div>
  </v-container>
</template>

<script>
import GoogleReCaptcha from "@/gapp-components/components/display/GoogleReCaptcha.vue";
import TermsAndConditionsView from "@/gapp-components/components/display/TermsAndConditionsView.vue";
import EmailField from "@/gapp-components/components/fields/EmailField.vue";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import ValidationService from "@/gapp-components/services/validation.service.js";
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: { ApiError, EmailField, TermsAndConditionsView, GoogleReCaptcha },
  name: "CeurAdobeMaxPromotion",
  metaInfo: {
    title: "Adobe Max Promotion"
  },
  data() {
    return {
      isLoading: false,
      e1: 1,
      errors: {},
      errorMessage: null,
      promotionKey: "SADO10US24",
      isSuccess: false,
      isValid: false,
      isValidTermsAndConditions: false,
      rules: {
        firstName: ValidationService.generateTextValidationRules("First Name"),
        lastName: ValidationService.generateTextValidationRules("Last Name"),
        email: ValidationService.generateEmailValidationRules()
      },
      form: {
        programKey: process.env.VUE_APP_PROGRAM_CEUR_KEY,
        clientKey: process.env.VUE_APP_CLIENT_KEY,
        participantStatus: {},
        user: {
          firstName: "",
          lastName: "",
          email: "",
          username: ""
        },
        participant: {
          email1: "",
          phoneNumber1: "",
          address: {
            address1: "",
            address2: "",
            country: {},
            region: {},
            city: "",
            postalCode: ""
          },
          participantStatus: { name: "PRE" },
          termAndConditionAccepted: false
        },
        relationships: [],
        sendMail: true
      },
      program: null,
      finalDescription: "",
      termsAndConditions: {},
      finalTitle: "You must register here to receive the Rebate Offer via email",
      promotion: {},
      promotionDeadlines: [],
      noPromotionsMessage: this.$i18n.translate("Check back with us soon for new rebates!")
    };
  },
  created() {
    // clear existing errors
    this.$store.dispatch("logout");
    this.fetchData();
  },
  computed: {
    ...mapGetters(["selectedLocale", "selectedProgram", "selectedCountry"]),
    isAfterAllSubmissionDeadlines() {
      const currentDate = moment();
      return (
        this.promotionDeadlines?.every(deadline => currentDate.isAfter(moment(deadline.submissionDeadlineDate))) ||
        false
      );
    }
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.errors = {};
      this.errorMessage = null;
      Vue.set(this, "errors", []);

      // clear existing errors
      this.$store.dispatch("logout");
      var postForm = this.preparePostForm();

      // Sending the request
      this.$api
        .postWithCaptcha("/api/promotionInvitations/selfInvitation/public", postForm, null, "AdobeMax")
        .then(() => {
          this.e1 = 2;
          this.finalTitle = "Your Registration was successful.";
          this.finalDescription =
            "An email will be sent shortly to the email address you have provided on the form.<br><br>Thank you for choosing Sony! <br><br>";
          this.isSuccess = true;
        })
        .catch(error => {
          let errorDescription = "";
          errorDescription = this.$api.getErrorsFromResponse(error).message;
          this.e1 = 2;
          this.finalTitle = "Registration error";
          this.finalDescription = errorDescription.startsWith("The user is already registered")
            ? "The email address you have provided has already been registered. Please call 1.888.793.1332 and request the email resent. <br><br> Thank you for choosing Sony!"
            : errorDescription;
          this.isSuccess = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    preparePostForm() {
      const { participant, email, organization } = this.form;
      return {
        promotionKey: this.selectedCountry.name === "CAN" ? "SADO10CA24" : this.promotionKey,
        participant: {
          ...participant,
          participantType: this.selectedProgram.defaultOnEnrollParticipantType,
          user: this.form.user,
          email1: email,
          organization,
          address: { ...participant.address, country: { name: "USA" } }
        }
      };
    },

    backToLanding() {
      this.$router.push({ name: "ceurLanding" });
    },

    onBack() {
      this.finalTitle = "You must register here to receive the Rebate Offer via email";
      this.e1 -= 1;
    },

    async fetchData() {
      this.isLoading = true;
      const selectedLocaleName = this.selectedLocale?.languageType?.name || "en";
      try {
        const { data } = await this.$api.get(`/api/promotions/byPromotionKey/${this.promotionKey}/public`);
        this.promotion = data?.promotion || {};
        this.promotionDeadlines = data?.promotionDeadlines || [];
        const termsAndConditions =
          data?.promotion?.promotionType?.termsAndConditions?.filter(tac => tac.language.name === selectedLocaleName) ||
          [];
        if (termsAndConditions.length > 0) {
          this.termsAndConditions = termsAndConditions[0];
        }
      } catch (error) {
        console.error("Error fetching promotion data:", error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
<style>
.grecaptcha-badge {
  bottom: 40px !important;
}
</style>
